import { Typography } from '@mui/material';
import React from 'react'

export const LabelTypography = ({children, ...props}: {children: string}) => {
    return (
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: "400",
          color: "third",
        }}
        {...props}
      >
        {children}
      </Typography>
    );
}
