import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps, Theme } from "@mui/system";

type Props = {
  label: string;
  loading?: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
  
};
export const ConfirmButton = ({ label, loading, onClick, sx,  ...rest }: Props) => {
  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        textTransform: 'none',
        backgroundColor: "primary.main",
        color: "white",
        fontSize: "16px",
        fontWeight: "700",
        borderRadius: "4px",
        height: "50px",
        width: "100%",
        boxShadow: "0 0",
        "&:hover": {
          backgroundColor: "primary.main",
        },
        ...sx
      }}
      {...rest}
    >
      {label}
    </LoadingButton>
  );
};
