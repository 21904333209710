"use client";

import React, { useCallback, useContext, useState } from "react";
import { LabelTypography } from "@/components/label-typography";
import { StyledTextField } from "@/components/styled-textfield";
import { ConfirmButton } from "@/components/confirm-button";
import Link from "next/link";
import { Box, Grid, Typography } from "@mui/material";
import Image from "next/image";
import { useAuth } from "@/context/auth";

import PasswordInput from "@/components/password-input";
import { UserLoggedInProps } from "@/type/user";
import { useTranslation } from "@/app/i18n/client";

export default function LoginForm({
  lang,
  logo,
}: {
  lang: string;
  logo: string;
}) {
  const { t } = useTranslation(lang, "translation", {});

  const { loggedIn, loginErrorMsg } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState<UserLoggedInProps>({
    email: "",
    password: "",
  });

  const onInputChange = useCallback(
    (prop: string, value: string | number) => {
      setUserData({
        ...userData,
        [prop]: value,
      });
    },
    [userData]
  );

  const onSubmit = async () => {
    setLoading(true);
    try {
      await loggedIn(userData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "4px",
        p: "24px",
        pt: "33px",
        boxShadow: "rgba(0, 0, 0, 0.17) 0px 1px 4px",
        width: { lg: "90%" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
          mb: "24px",
        }}
      >
        <Image
          src={logo || "/images/logo.png"}
          alt="logo"
          width={100}
          height={100}
        />
        <Typography
          sx={{ color: "secondary", fontSize: "18px", fontWeight: "700" }}
        >
          {t("hello")}
        </Typography>
        <Typography sx={{ color: "third", fontSize: "14px" }}>
          {t("login-to-your-account")}
        </Typography>
      </Box>

      <Grid container>
        <Grid item xs={12} marginBottom={2}>
          <LabelTypography>{t("email-mobile")}</LabelTypography>
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <StyledTextField
            value={userData.email}
            onChange={(e) => onInputChange("email", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} marginBottom={2}>
          <LabelTypography>{t("password")}</LabelTypography>
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <PasswordInput
            name="password"
            onInputChange={onInputChange}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            value={userData.password}
          />
        </Grid>

        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "flex-end", md: "space-between" }}
          alignItems={{
            xs: lang === "en" ? "flex-start" : "flex-end",
            lg: "center",
          }}
          marginBottom={2}
          gap={"8px"}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "red",
              fontWeight: "400",
            }}
          >
            {loginErrorMsg}
          </Typography>

          {
            //TODO: unhide when forget password is implemented
            /* <Link href={"/forgot-password"} legacyBehavior={true}>
              <Typography
                sx={{ fontSize: "14px", color: "primary.main", fontWeight: "700", "&:hover": { cursor: "pointer" }, textDecoration: 'underline' }}
              >
            {t("forgot-password?")}
            </Typography>
            </Link> */
          }
        </Grid>

        <Grid item xs={12} marginBottom={"32px"}>
          <ConfirmButton
            label={t("sign-in")}
            onClick={onSubmit}
            loading={loading}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mb: "40px" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography sx={{ fontSize: "14px", color: "secondary.main" }}>
              {t("do-not-have-an-account?")}
            </Typography>
            <Link href={"/signup"} legacyBehavior={true}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "primary.main",
                  fontWeight: "700",
                  "&:hover": { cursor: "pointer" },
                  textDecoration: "underline",
                }}
              >
                {t("register-now")}
              </Typography>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ display: "none", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              color: "third",
              fontSize: "12px",
              fontWeight: "300",
            }}
          >
            <Link href={"/privacy-policy"} style={{ textDecoration: "none" }}>
              <Typography sx={{ fontSize: "12px", color: "third" }}>
                {t("privacy-policy")}
              </Typography>
            </Link>
            <Link
              href={"/terms-and-conditions"}
              style={{ textDecoration: "none" }}
            >
              <Typography sx={{ fontSize: "12px", color: "third" }}>
                {t("terms-and-conditions")}
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
