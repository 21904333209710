"use client";
import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/system";

const TextField = styled(MuiTextField)(({ theme }) => ({
  height: "50px",
  color: "text.black",
  paddingLeft: "10px",
  "& .MuiInputBase-input": {
    color: "#00000099",
    fontSize: "14px",
    "&::before": {
      borderBottom: "none",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
}));

type TextFieldProps = {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
  placeholder?: string;
  disabled?: boolean;
};
export const StyledTextField = (props: TextFieldProps) => {
  const { sx, ...otherProps } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        disableUnderline: true,
      }}
      variant="standard"
      sx={{
        borderRadius: "4px",
        backgroundColor: "secondaryBackground",
        border: "1px solid",
        borderColor: "gray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "input:-webkit-autofill": {
          "-webkit-box-shadow": "0 0 0 1000px #FAFAFA inset ",
        },
        "input:-webkit-autofill:hover": {
          "-webkit-box-shadow": "0 0 0 1000px #FAFAFA inset ",
        },
        "input:-webkit-autofill:focus": {
          "-webkit-box-shadow": "0 0 0 1000px #FAFAFA inset ",
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};
