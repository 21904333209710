import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { OutlinedInput } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
interface PasswordInputProps {
 value: string;
  onInputChange: (name: string, value: string) => void;
  showPassword: boolean;
  handleClickShowPassword: () => void;
  name: string,
  border?: boolean,
  sx?: SxProps<Theme>;
}

export default function PasswordInput({
  value,
  onInputChange,
  showPassword,
  handleClickShowPassword,
  name,
  border= true,
  sx
}: PasswordInputProps) {
  return (
    <OutlinedInput
      fullWidth
      value={value}
      onChange={(e) => onInputChange(name, e.target.value)}
      type={showPassword ? "text" : "password"}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      sx={{
        height: "50px",
        borderRadius: "4px",
        backgroundColor: "secondaryBackground",
        border: "1px solid",
        borderColor:  border ? "gray" : 'transparent',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&.MuiInputBase-root ": {
          border: "none",
        },
        "& .MuiOutlinedInput-notchedOutline ": {
          borderColor: border ? "gray" : 'transparent',
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: border ? "gray" : 'transparent',
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid ",
           borderColor: border ? "gray" : 'transparent'
        },
        ...sx
      }}
    />
  );
}
